// TODO: Remove this after done with AB test
import Spinner from '@alexis/components/Spinner';
import React, { lazy, Suspense, useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import type { CurrentSite } from '@wego/alexis/types/helpers/currentSite';
import type { User } from '@wego/alexis/types/helpers/user';

import { GlobalContext } from '@context/GlobalContext';

import { Currency } from '@wegoTypes/currency';
import type { Translations } from '@wegoTypes/translations';

import styles from './HotelBookingWithABTest.module.scss';

const NewHotelBooking = lazy(
  () => import(/* webpackChunkName: "HotelBooking" */ '@pages/HotelBookingNew'),
);

const HotelBooking = lazy(
  () => import(/* webpackChunkName: "HotelBooking" */ '@pages/HotelBooking'),
);

interface HotelBookingWithABTestProps {
  apiBaseUrl: string;
  currency: Currency;
  currentSite: CurrentSite;
  exchangeRate: ExchangeRate;
  gtmAuth: string;
  gtmPreview: string;
  isRtl: boolean;
  locale: string;
  localeParam: string | undefined;
  translations: Translations;
  user: User | null;
  wegoAnalyticsClientId: string | undefined;
  wegoAnalyticsClientSessionId: string | undefined;
}

const HotelBookingWithABTest: React.FC<HotelBookingWithABTestProps> = ({
  apiBaseUrl,
  currency,
  currentSite,
  exchangeRate,
  gtmAuth,
  gtmPreview,
  isRtl,
  locale,
  localeParam,
  translations,
  user,
  wegoAnalyticsClientId,
  wegoAnalyticsClientSessionId,
}) => {
  const { userCentricCoreConfigs } = useContext(GlobalContext);
  const newHotelBookingCoreConfig = userCentricCoreConfigs.find(
    (coreConfig) => coreConfig.key === 'HIB_2652_new_hotel_booking_page',
  )?.value;

  const [searchParams] = useSearchParams();
  const isNewBookingPage = searchParams.get('new_booking') || newHotelBookingCoreConfig === '1';
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (userCentricCoreConfigs.length > 0) {
      setIsLoading(false);
    }
  }, [userCentricCoreConfigs]);

  // Handle the case when the userCentricCoreConfigs is failed to load
  useEffect(() => {
    if (isLoading) {
      const delayId = setTimeout(() => {
        setIsLoading(false);
      }, 15000);

      return () => {
        clearTimeout(delayId);
      };
    }
  }, [isLoading, userCentricCoreConfigs]);

  return (
    <Suspense fallback={null}>
      {!isLoading ? (
        <>
          {isNewBookingPage ? (
            <NewHotelBooking
              gtmAuth={gtmAuth}
              gtmPreview={gtmPreview}
              localeParam={localeParam}
              wegoAnalyticsClientId={wegoAnalyticsClientId}
              wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
            />
          ) : (
            <HotelBooking
              apiBaseUrl={apiBaseUrl}
              currency={currency}
              currentSite={currentSite}
              exchangeRate={exchangeRate}
              gtmAuth={gtmAuth}
              gtmPreview={gtmPreview}
              isRtl={isRtl}
              locale={locale}
              localeParam={localeParam}
              translations={translations}
              user={user}
              wegoAnalyticsClientId={wegoAnalyticsClientId}
              wegoAnalyticsClientSessionId={wegoAnalyticsClientSessionId}
            />
          )}
        </>
      ) : (
        <div className={styles.spinnerWrapper}>
          <div className={styles.spinner}>
            <Spinner className={styles.innerSpinner} />
          </div>
        </div>
      )}
    </Suspense>
  );
};

export default HotelBookingWithABTest;
