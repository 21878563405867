import { countriesConfig, getCountryConfig } from '@alexis/helpers/country';
import { dateDisplayFormat } from '@alexis/helpers/date';
import { FeePrice } from '@pages/FlightBookingPage/hooks/useGetPaymentFees';
import { Fee } from '@wego/payments-react-component/dist/types/components/PaymentForm/models';
import { isEmpty, uniqBy } from 'lodash-es';

import { translateText } from '@wego/alexis/helpers/translation';
import { Country } from '@wego/alexis/types/helpers/country';

import { SALUTATION } from '@constants/flightBooking';

import { convertDateStringToDateApiFormat, getDateWithUTCtimeZone } from '@helpers/flights/date';

import {
  FlightBookingInsurancePolicy,
  AddonFarePrice,
  Penalty,
  PaymentCodes,
  BookingStatus,
  PassengerType,
  PassengerFormDetails,
  FlightSegmentDetails,
  TravellerDetailsRequestPayload,
  BrandedFarePrice,
  PaymentProcessingFee,
  FlightPromoCodeResponse,
  BrandedFarePassengerInfo,
} from '@wegoTypes/flightBooking';
import { BookingStatusText } from '@wegoTypes/flightBooking/bookingStatusText';
import {
  Passenger,
  SegmentDetails,
  Itinerary,
} from '@wegoTypes/flights/FlightBookingDetailsResponse';
import type { FlightFareResultTripLeg } from '@wegoTypes/flights/metasearch/flightFareResultTripLeg';
import type { Translations } from '@wegoTypes/translations';

function escapeRegExp(value: string): string {
  return value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export function fillEndpointWithParams(url: string, params: Array<string>): string {
  params.forEach((param: string, index: number) => {
    const replacement = new RegExp(escapeRegExp(`{${index}}`), 'g');

    url = url.replace(replacement, param);
  });
  return url.trim();
}

export function convertBrandedFareOriginalPriceToPrice(brandedFarePrice: BrandedFarePrice): Price {
  return {
    amount: brandedFarePrice.totalOriginalAmount,
    amountUsd: brandedFarePrice.totalOriginalAmountUsd,
    currencyCode: brandedFarePrice.currencyCode,
  };
}

export function computeTotalInsurancePrice(
  insurances: Array<FlightBookingInsurancePolicy>,
): AddonFarePrice {
  return insurances.reduce(
    (total: AddonFarePrice, insurance: FlightBookingInsurancePolicy): AddonFarePrice => {
      return {
        currencyCode: insurance.price.currencyCode,
        totalAmount: total.totalAmount + insurance.price.totalAmount,
        totalAmountUsd: total.totalAmountUsd + insurance.price.totalAmountUsd,
        totalTaxAmount: total.totalTaxAmount + insurance.price.totalTaxAmount,
        totalTaxAmountUsd: total.totalTaxAmountUsd + insurance.price.totalTaxAmountUsd,
      };
    },
    {
      currencyCode: '',
      totalAmount: 0.0,
      totalAmountUsd: 0.0,
      totalTaxAmount: 0.0,
      totalTaxAmountUsd: 0.0,
    },
  );
}

export function convertPenaltyToPrice(currencyCode: string, penalty?: Penalty): Price {
  return {
    amount: penalty?.amount ?? 0,
    amountUsd: penalty?.amountUsd ?? 0,
    currencyCode: penalty?.currencyCode ?? currencyCode,
  };
}

export function getCabinClass(cabinClass: string, translations: Translations): string {
  switch (cabinClass) {
    case 'economy':
      return translations.economy as string;
    case 'premium_economy':
      return translations.form_cabin_premium_economy as string;
    case 'business':
      return translations.business_class as string;
    case 'first':
      return translations.first_class as string;
    default:
      return '';
  }
}

export function getPaymentCode(paymentCode: PaymentCodes): string {
  switch (paymentCode) {
    case 'applepay':
      return 'applePay';
    case 'visc':
      return 'visa';
    case 'masc':
      return 'masterCard';
    case 'madc':
      return 'mada';
    case 'knet':
      return 'knet';
    case 'amex':
      return 'amex';
    case 'xpay':
      return 'creditCard';
    case 'bnpl_3_installments':
    case 'bnpl_4_installments':
      return 'tabby';
    default:
      return 'invalidCard';
  }
}

export function getPaymentScheme(
  paymentScheme: 'AMERICAN EXPRESS' | 'American Express' | 'Mastercard' | 'Visa' | 'visa',
): string {
  const scheme = paymentScheme.toUpperCase();
  switch (scheme) {
    case 'VISA':
      return 'visa';
    case 'MASTERCARD':
      return 'masterCard';
    case 'AMERICAN EXPRESS':
      return 'amex';
    default:
      return 'invalidCard';
  }
}

export const getBookingStatus = (
  bookingStatus: BookingStatus,
): 'success' | 'failed' | 'pending' | 'retry' => {
  switch (bookingStatus) {
    case BookingStatus.Success:
      return 'success';
    case BookingStatus.Polling:
    case BookingStatus.PollingAfterSuccessfulPayment:
      return 'pending';
    case BookingStatus.AllowRetryPayment:
      return 'retry';
    default:
      return 'failed';
  }
};

export const getBookingStatusFailureType = (
  bookingStatus: BookingStatus,
): 'general' | 'payment' | 'retry' => {
  switch (bookingStatus) {
    case BookingStatus.GenericPaymentFailureWithBookingReference:
    case BookingStatus.GenericPaymentFailureWithoutBookingReference:
    case BookingStatus.PaymentFailed:
    case BookingStatus.CardFormatErrorWithBookingReference:
    case BookingStatus.CardFormatErrorWithoutBookingReference:
      return 'payment';
    case BookingStatus.AllowRetryPayment:
      return 'retry';
    default:
      return 'general';
  }
};

export function getBookingStatusString(bookingStatus: BookingStatus): string {
  switch (bookingStatus) {
    case BookingStatus.Success:
      return 'Success';
    case BookingStatus.BookingFailed:
      return 'BookingFailed';
    case BookingStatus.PaymentFailed:
      return 'PaymentFailed';
    case BookingStatus.CardFormatErrorWithBookingReference:
      return 'CardFormatErrorWithBookingReference';
    case BookingStatus.ThreeDSErrorWithBookingReference:
      return 'ThreeDSErrorWithBookingReference';
    case BookingStatus.TryAnotherCardWithBookingReference:
      return 'TryAnotherCardWithBookingReference';
    case BookingStatus.GenericPaymentFailureWithBookingReference:
      return 'GenericPaymentFailureWithBookingReference';
    case BookingStatus.CardFormatErrorWithoutBookingReference:
      return 'CardFormatErrorWithoutBookingReference';
    case BookingStatus.ThreeDSErrorWithoutBookingReference:
      return 'ThreeDSErrorWithoutBookingReference';
    case BookingStatus.TryAnotherCardWithoutBookingReference:
      return 'TryAnotherCardWithoutBookingReference';
    case BookingStatus.GenericPaymentFailureWithoutBookingReference:
      return 'GenericPaymentFailureWithoutBookingReference';
    case BookingStatus.GeneralError:
      return 'GeneralError';
    default:
      return 'Polling';
  }
}

export const getSalutation = (gender: 'MALE' | 'FEMALE', paxType: PassengerType): string => {
  if (gender === 'MALE') {
    return paxType === 'ADULT' ? SALUTATION.Mr : SALUTATION.Master;
  } else if (gender === 'FEMALE') {
    return paxType === 'ADULT' ? SALUTATION.Mrs : SALUTATION.Miss;
  }
  return '';
};

export const getTravellerRequestPayload = (
  passengerDetails: PassengerFormDetails,
  id?: number,
): TravellerDetailsRequestPayload => {
  const {
    firstName,
    lastName,
    gender,
    type,
    dateOfBirth,
    nationalityCountryCode,
    passengerDocument,
  } = passengerDetails;
  return {
    travellers: [
      {
        first_name: firstName,
        middle_name: '',
        last_name: lastName,
        title: gender !== undefined && gender !== null ? getSalutation(gender, type) : '',
        date_of_birth: convertDateStringToDateApiFormat(dateOfBirth),
        nationality: nationalityCountryCode,
        traveller_type: type,
        gender: gender,
        traveller_id_type: passengerDocument?.type,
        traveller_id_no: passengerDocument?.number,
        traveller_id_issued_at:
          !!passengerDocument && !!passengerDocument?.issueDate
            ? convertDateStringToDateApiFormat(passengerDocument.issueDate)
            : '',
        traveller_id_expiry:
          !!passengerDocument && !!passengerDocument?.expiryDate
            ? convertDateStringToDateApiFormat(passengerDocument?.expiryDate)
            : '',
        traveller_id_country: nationalityCountryCode,
        ...(!!id && { id }),
      },
    ],
  };
};

export const getSegmentDetails = (
  departureLeg: FlightFareResultTripLeg,
  returnLeg?: FlightFareResultTripLeg,
): Array<FlightSegmentDetails> => {
  let tripSegments = departureLeg.segments;
  if (!isEmpty(returnLeg) && !!returnLeg) {
    tripSegments = [...tripSegments, ...returnLeg.segments];
  }
  return tripSegments.map((segment) => ({
    airline_codes: segment.airlineCode ?? '',
    arrival_airport_code: segment.arrivalAirportCode ?? '',
    arrival_city_code: segment.arrivalAirportCode ?? '',
    arrival_city_name: segment.arrivalCityName ?? '',
    arrival_continent_code: '',
    arrival_continent_name: '',
    arrival_country_code: segment.arrivalCountryCode ?? '',
    arrival_country_name: segment.arrivalCountryCode
      ? getCountryConfig(segment.arrivalCountryCode)?.name ?? ''
      : '',
    departure_airport_code: segment.departureAirportCode ?? '',
    departure_city_code: segment.departureAirportCode ?? '',
    departure_city_name: segment.departureCityName ?? '',
    departure_continent_code: '',
    departure_continent_name: '',
    departure_country_code: segment.departureCountryCode ?? '',
    departure_country_name: segment.departureCountryCode
      ? getCountryConfig(segment.departureCountryCode)?.name ?? ''
      : '',
    inbound_date: segment.arrivalDateTime ?? '',
    outbound_date: segment.departureDateTime ?? '',
  }));
};

export function convertBrandedFarePriceToPrice(brandedFarePrice: BrandedFarePrice): Price {
  return {
    amount: brandedFarePrice.totalAmount,
    amountUsd: brandedFarePrice.totalAmountUsd,
    currencyCode: brandedFarePrice.currencyCode,
  };
}

export function sumAllBrandedFares(
  brandedFarePrices: Array<BrandedFarePrice>,
  addOnFarePrice?: AddonFarePrice | undefined,
  paymentProcessingFee?: PaymentProcessingFee | (Fee & { amountInUsd: number }) | undefined,
  promo?: FlightPromoCodeResponse,
): Price {
  const price = {
    amount: 0,
    amountUsd: 0,
    currencyCode: brandedFarePrices[0].currencyCode,
  };
  brandedFarePrices.forEach((brandedFarePrice) => {
    const brandedFare = convertBrandedFarePriceToPrice(brandedFarePrice);
    price.amount += brandedFare.amount;
    price.amountUsd += brandedFare.amountUsd;
  });

  if (!isEmpty(addOnFarePrice) && !!addOnFarePrice) {
    price.amount += addOnFarePrice.totalAmount;
    price.amountUsd += addOnFarePrice.totalAmountUsd;
  }

  if (!isEmpty(paymentProcessingFee) && !!paymentProcessingFee) {
    const totalProcessingFee = paymentProcessingFee.amount;
    const totalProcessingInUsd = paymentProcessingFee.amountInUsd;
    price.amount += totalProcessingFee;
    price.amountUsd += totalProcessingInUsd;
  }

  if (!!promo && !!promo?.applicable) {
    price.amount -= promo.amount;
    price.amountUsd -= promo.amountUsd;
  }

  return price;
}

export function getPaymentProcessingFee(processingFee: PaymentProcessingFee): Price {
  const totalProcessingFee = processingFee.amount;
  const totalProcessingInUsd = processingFee.amountInUsd;

  return {
    amount: totalProcessingFee,
    amountUsd: totalProcessingInUsd,
    currencyCode: processingFee.currencyCode,
  };
}

export function sumAllBrandedFaresOriginalAmount(
  brandedFarePrices: Array<BrandedFarePrice>,
): Price {
  const price = {
    amount: 0,
    amountUsd: 0,
    currencyCode: brandedFarePrices[0].currencyCode,
  };
  brandedFarePrices.forEach((brandedFarePrice) => {
    const brandedFare = convertBrandedFareOriginalPriceToPrice(brandedFarePrice);
    price.amount += brandedFare.amount;
    price.amountUsd += brandedFare.amountUsd;
  });

  return price;
}

export function sumAllBrandedFaresPrice(
  brandedFarePrices: Array<BrandedFarePrice>,
  addOnFarePrice?: AddonFarePrice | undefined,
  paymentProcessingFee?: PaymentProcessingFee | FeePrice | undefined,
  promo?: FlightPromoCodeResponse,
): Price {
  const price = {
    amount: 0,
    amountUsd: 0,
    currencyCode: brandedFarePrices[0].currencyCode,
  };
  brandedFarePrices.forEach((brandedFarePrice) => {
    const brandedFare = convertBrandedFarePriceToPrice(brandedFarePrice);
    price.amount += brandedFare.amount;
    price.amountUsd += brandedFare.amountUsd;
  });

  if (!!addOnFarePrice) {
    price.amount += addOnFarePrice.totalAmount;
    price.amountUsd += addOnFarePrice.totalAmountUsd;
  }

  if (!!paymentProcessingFee) {
    const totalProcessingFee = paymentProcessingFee.amount;
    const totalProcessingInUsd = paymentProcessingFee.amountInUsd;
    price.amount += totalProcessingFee;
    price.amountUsd += totalProcessingInUsd;
  }

  if (!!promo?.applicable) {
    price.amount -= promo.amount;
    price.amountUsd -= promo.amountUsd;
  }

  return price;
}

export function formatDisplayDate(
  date: Date,
  translations: Translations,
  locale: string,
  formatType: number,
) {
  return dateDisplayFormat(
    date,
    translations.short_months as Array<string>,
    translations.short_weekdays as Array<string>,
    locale === 'fa',
    formatType,
  );
}

export function getCountryConfigOnlyByCallingCode(callingCode: string): Country | undefined {
  const selectedCountry = countriesConfig.find(
    (country) => country.callingCode.toString() === callingCode.replace('+', ''),
  );
  return selectedCountry;
}

export const getAirlinePnrByPassenger = (
  segments: SegmentDetails[],
  passenger: Passenger,
): string => {
  const eticketViews = segments
    .map((segment) => segment.eticketViews)
    .reduce((acc, val) => acc.concat(val), []);
  const airlinePnrs = eticketViews.filter(
    (eticketView) => eticketView.passengerId === passenger.id,
  );
  return uniqBy(
    airlinePnrs.map((airlinePnr) => airlinePnr.airlineRef),
    'airlineRef',
  ).join(' / ');
};

export const getBookingStatusByText = (bookingStatusCode?: BookingStatusText) => {
  switch (bookingStatusCode) {
    case BookingStatusText.ticketed:
    case BookingStatusText.completed:
    case BookingStatusText.confirmed:
      return 'success';
    case BookingStatusText.failed:
      return 'failed';
    case BookingStatusText.validated:
    case BookingStatusText.reviewInProcess:
      return 'pending';
    case BookingStatusText.offline:
      return 'offline';
    default:
      return 'default';
  }
};

export const getBookingStatusMsg = (
  bookingStatusText: BookingStatusText | undefined,
  isLoading: boolean,
  translations: Translations,
  locale: string,
  userEmail: string | null,
  refundDetails?:
    | {
        currencyCode: string;
        refundAmount: number;
        refundDate: string;
        chargedCurrencyCode: string;
        refundAmountInChargedCurrency: number;
      }
    | undefined,
) => {
  if (isLoading && !bookingStatusText) {
    return {
      title: translations.processing_your_booking as string,
      message: translations.bow_flights_loading_desc as string,
      subTitle: translations.please_wait as string,
    };
  }

  let status = {
    title: translations.processing_your_booking as string,
    message: translations.please_wait as string,
    subTitle: '',
  };
  switch (bookingStatusText) {
    case BookingStatusText.ticketed:
    case BookingStatusText.completed:
      status.title = translations.hope_pleasant_flight as string;
      status.message = translations.next_adventure as string;
      status.subTitle = translations.thanks_for_booking_wego as string;
      break;
    case BookingStatusText.confirmed:
      status.title = translations.booking_confirmed as string;
      status.message = translateText(
        translations.booking_confirm_desc,
        locale ?? '',
        userEmail ?? '',
      );
      break;
    case BookingStatusText.onHold:
      status.title = translations.booking_on_hold as string;
      status.message = translations.booking_on_hold_desc as string;
      break;
    case BookingStatusText.refundIsProcessed:
    case BookingStatusText.processingRefund:
      status.title = translations.booking_cancelled as string;
      status.message = !!refundDetails
        ? translateText(
            translations.booking_in_process_desc,
            locale,
            refundDetails?.chargedCurrencyCode,
            refundDetails?.refundAmountInChargedCurrency?.toFixed(2),
            getDateWithUTCtimeZone(refundDetails.refundDate, locale),
          )
        : '';
      break;
    case BookingStatusText.cancelled:
      status.title = translations.booking_cancelled as string;
      status.message = translations.booking_cancelled_desc as string;
      break;

    case BookingStatusText.reviewInProcess:
      status.title = translations.processing_your_booking as string;
      status.message = translations.thanks_for_choosing_wego as string;
      break;
    case BookingStatusText.validated:
      status.title = translations.booking_in_process as string;
      status.message = translations.booking_in_review_desc as string;
      break;
    case BookingStatusText.failed:
      status.title = translations.booking_failed as string;
      status.message = translations.bow_flights_failed_desc as string;
      break;
    case BookingStatusText.offline:
      status.title = translations.booking_modified_offline as string;
      status.message = translateText(
        translations.booking_modified_offline_desc,
        locale ?? '',
        userEmail ?? '',
      );
      break;
    default:
      status.title = translations.unable_to_find_booking as string;
      status.message = translations.retrieve_booking_message as string;
      break;
  }
  return status;
};

export function getTotalPrice<T>(
  arrayOfObject: T[],
  keysToExtract: { amountKey: keyof T; amountInUsdKey: keyof T; currencyCodeKey: keyof T },
): Price {
  return arrayOfObject.reduce(
    (price, object) => {
      const { amountKey, amountInUsdKey, currencyCodeKey } = keysToExtract;
      const amount = object[amountKey] as unknown as number;
      const amountInUsd = object[amountInUsdKey] as unknown as number;
      const currencyCode = object[currencyCodeKey] as unknown as string;

      return {
        amount: price.amount + (amount as number),
        amountUsd: price.amountUsd + (amountInUsd as number),
        currencyCode,
      } as Price;
    },
    {
      amount: 0,
      amountUsd: 0,
      currencyCode: '',
    },
  );
}

export function getTripInfo(itineraries: Itinerary[]) {
  const isRoundTrip = itineraries[0].legs.length > 1;
  if (isRoundTrip) {
    // For round trip, we only need to show the 2nd leg info
    const leg = itineraries[0].legs[1];
    return [
      {
        legId: leg.legId,
        departureCityName: leg.departureCityName,
        arrivalCityName: leg.arrivalCityName,
        airlineCode: leg.airlineCodes,
        status: leg.status,
      },
    ];
  }

  const legsWithLegId = itineraries.map((itinerary) => {
    return itinerary.legs.map((leg) => {
      return {
        ...leg,
        legId: itinerary.legId,
      };
    });
  });

  const legs = legsWithLegId.reduce((acc, leg) => acc.concat(leg), []);

  return legs.map((leg) => {
    return {
      legId: leg.legId,
      departureCityName: leg.departureCityName,
      arrivalCityName: leg.arrivalCityName,
      airlineCode: leg.airlineCodes,
      status: leg.status,
    };
  });
}
export const getBaggageDetail = (
  passengerInfos: Array<BrandedFarePassengerInfo>,
  baggageId: number,
  paxCount: Record<string, number>,
) => {
  const paxInfo = {
    adult: 0,
    child: 0,
    infant: 0,
  };
  let legInfo = [
    {
      legIndex: 0,
      segmentIndexes: [] as number[],
      paxInfo: { ...paxInfo },
    },
  ];
  for (let passengerInfo of passengerInfos) {
    const { baggages } = passengerInfo;

    for (let legIndex = 0; legIndex < baggages.length; legIndex++) {
      const leg = baggages[legIndex];
      for (let segmentIndex = 0; segmentIndex < leg.length; segmentIndex++) {
        const segment = leg[segmentIndex];
        if (segment.includes(baggageId)) {
          legInfo[legIndex] = {
            legIndex,
            segmentIndexes: Array.from(
              new Set([...(legInfo[legIndex]?.segmentIndexes || []), segmentIndex]),
            ),
            paxInfo: {
              ...(legInfo[legIndex]?.paxInfo || { adult: 0, child: 0, infant: 0 }),
              [passengerInfo.type.toLowerCase() as keyof typeof paxInfo]: Number(
                paxCount[passengerInfo.type.toLowerCase()] || 0,
              ),
            },
          };
        }
      }
    }
  }

  return { legInfo };
};
