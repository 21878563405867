import { sessionStorageGet } from '@alexis/helpers/sessionStorage';
import useGetPaymentOptions from '@pages/FlightBookingPage/hooks/useGetPaymentOptions';
import { SegmentAncillaries } from '@pages/FlightBookingPage/types';
import { PaymentOptionsResponse } from '@wego/payments-react-component';
import {
  Fee,
  PaymentCustomerInfo,
  PaymentMethod,
} from '@wego/payments-react-component/dist/types/components/PaymentForm/models';
import { OrderData } from '@wego/payments-react-component/dist/types/helpers/evaluateCustomers';
import { PaymentMethodsResponse } from '@wego/payments-react-component/dist/types/helpers/paymentOptions/models';
import { FlightBookingDetailsSessionStorage } from 'flightBooking';
import { createContext, FC, ReactNode, useContext, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { sessionStorageSave } from '@wego/alexis/helpers/sessionStorage';

import { useGetSegmentAncillaries } from '@hooks/api/flights/useGetSegmentAncillaries';

import { PaymentFee } from '../hooks/useGetPaymentFees';

interface BookingPageContextProps {
  selectedPaymentMethod: PaymentMethod | undefined;
  setSelectedPaymentMethod: (paymentMethod: PaymentMethod) => void;
  processingFee?: (Fee & { amountInUsd: number }) | undefined;
  paymentOptions?: PaymentOptionsResponse[];
  isGettingPaymentOptions?: boolean;
  isGettingPaymentMethodFees?: boolean;
  paymentFees?: PaymentFee[] | undefined;
  loggedInUser?: PaymentCustomerInfo;
  primaryRecipient: PaymentCustomerInfo;
  orderData?: OrderData;
  selectedPaymentOption?: PaymentMethodsResponse;
  isSeatSelectionAvailable?: boolean;
  seatSelectionAncillaries?: SegmentAncillaries[];
}
const Context = createContext<BookingPageContextProps>({
  selectedPaymentMethod: undefined,
  setSelectedPaymentMethod: () => {},
  processingFee: undefined,
  paymentOptions: [],
  isGettingPaymentOptions: undefined,
  isGettingPaymentMethodFees: undefined,
  paymentFees: undefined,
  loggedInUser: undefined,
  primaryRecipient: {
    email: '',
    fullName: '',
    phoneNumber: '',
  },
  orderData: undefined,
  selectedPaymentOption: undefined,
  isSeatSelectionAvailable: false,
  seatSelectionAncillaries: undefined,
});
export const useBookingPageContext = () => useContext<BookingPageContextProps>(Context);
export const BookingPageProvider: FC<{ children?: ReactNode | undefined }> = (props) => {
  const [searchParams] = useSearchParams();
  const orderIdSearchParam = searchParams.get('orderId') ?? '';

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod>();

  const { data: ancillaries } = useGetSegmentAncillaries();

  const {
    paymentOptions,
    isLoading: isGettingPaymentOptions,
    paymentFees,
    isGettingPaymentMethodFees,
    loggedInUser,
    primaryRecipient = {
      email: '',
      fullName: '',
      phoneNumber: '',
    },
    orderData,
  } = useGetPaymentOptions(orderIdSearchParam);

  const selectedPaymentOption = useMemo<PaymentMethodsResponse | undefined>(() => {
    if (!selectedPaymentMethod) return undefined;

    const rs = paymentOptions
      .map((option) => option.paymentMethods)
      .flat()
      .find((option) => option.code === selectedPaymentMethod.code);
    const flightBookingDetails =
      sessionStorageGet<FlightBookingDetailsSessionStorage>(orderIdSearchParam) || {};
    sessionStorageSave(orderIdSearchParam, {
      ...flightBookingDetails,
      newSelectedPaymentOption: rs,
    });

    return rs;
  }, [selectedPaymentMethod, paymentOptions]);

  const processingFee = useMemo(() => {
    if (!orderIdSearchParam) {
      return undefined;
    }
    const rs = paymentFees?.find(
      (fee) => fee.paymentMethodCode === selectedPaymentMethod?.code,
    )?.fee;
    sessionStorageSave(orderIdSearchParam, {
      ...sessionStorageGet<FlightBookingDetailsSessionStorage>(orderIdSearchParam),
      processingFee: rs,
    });
    return rs;
  }, [paymentFees, selectedPaymentMethod]);
  return (
    <Context.Provider
      value={{
        selectedPaymentMethod,
        setSelectedPaymentMethod,
        processingFee,
        paymentOptions,
        isGettingPaymentMethodFees,
        paymentFees,
        isGettingPaymentOptions,
        loggedInUser,
        primaryRecipient,
        orderData,
        selectedPaymentOption,
        isSeatSelectionAvailable: ancillaries?.isSeatSelectionAvailable,
        seatSelectionAncillaries: ancillaries?.segmentAncillaries,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
