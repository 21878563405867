import useSeatSelectionFeatureFlag from '@pages/FlightBookingPage/hooks/useSeatSelectionFeatureFlag';
import { SegmentAncillaries } from '@pages/FlightBookingPage/types';
import { useParams } from 'react-router-dom';

import { getAncillaries } from '@apis/flight';

import { useAxiosQuery, UseAxiosQueryOptions } from '@hooks/useAxiosQuery';

export const getSegmentAncillariesKey = 'segment-ancillaries';

type SegmentAncillariesType = {
  segmentAncillaries: SegmentAncillaries[];
  isSeatSelectionAvailable: boolean;
};

export const useGetSegmentAncillaries = (
  options?: UseAxiosQueryOptions<SegmentAncillariesType>,
) => {
  const { fareCode = '' } = useParams<{
    fareCode: string;
  }>();
  const isOnSeatSelectionFeatureFlag = useSeatSelectionFeatureFlag();

  return useAxiosQuery<SegmentAncillariesType>({
    queryKey: [getSegmentAncillariesKey, fareCode],
    queryFn: async () => {
      const response = await getAncillaries(fareCode);

      return {
        segmentAncillaries: response,
        isSeatSelectionAvailable:
          response.some((segment) => segment.seatSelectionSupported) || false,
      };
    },
    enabled: Boolean(fareCode && isOnSeatSelectionFeatureFlag),
    staleTime: 5 * 60 * 1000, // 5 minutes
    cacheTime: 5 * 60 * 1000, // 5 minutes
    ...options,
  });
};
