import { appendSearchParams } from '@alexis/helpers/searchParam';
import { SegmentAncillaries } from '@pages/FlightBookingPage/types';
import axios from 'axios';
import type { CancelToken } from 'axios';

import { getGenzoIdentifierId } from '@helpers/genzoIdentifierId';

import { SeatAvailabilityProps } from '@wegoTypes/flightBooking/seatSelection';
import {
  PriceCalendar,
  PriceCalendarPayload,
  FareCalendarPayload,
  FareCalendar,
} from '@wegoTypes/flights/metasearch/calendar';
import type { FlightSearchResultSearch } from '@wegoTypes/flights/metasearch/flightSearchResultSearch';

export async function getFlightAmenities(
  tripId: string,
  locale: string,
  cancelToken?: CancelToken,
): Promise<FlightAmenities> {
  const searchParams: any = {
    locale,
  };

  const url = appendSearchParams(
    `${API_BASE_URL}/v3/metasearch/flights/amenities/${tripId}`,
    searchParams,
  );

  const response = await axios.get<FlightAmenities>(url, {
    ...(!!getGenzoIdentifierId() && {
      headers: { 'X-Wego-Genzo-Identifier-Id': getGenzoIdentifierId() },
    }),
    cancelToken,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
}

export const getSupportedPaymentTypes = async (
  apiBaseUrl: string,
  locale: string,
  siteCode: string,
  cancelToken?: CancelToken,
): Promise<Array<HomepageFlightsPaymentMethod>> => {
  const url = appendSearchParams(`${apiBaseUrl}/flights/payment_methods`, {
    locale,
    site_code: siteCode,
  });

  const response = await axios.get<Array<HomepageFlightsPaymentMethod>>(url, {
    cancelToken,
    withCredentials: true,
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export async function getFlightMetasearchDetails(
  searchId: string,
): Promise<FlightSearchResultSearch> {
  const url = `${API_BASE_URL}/v3/metasearch/flights/searches/${searchId}`;

  const response = await axios.get(url);

  if (response.status === 200) {
    return response.data;
  }

  throw new Error(response.statusText);
}

export const getPriceCalendar = async (payload: PriceCalendarPayload, cancelToken: CancelToken) => {
  const response = await axios.post<PriceCalendar[]>(
    `${API_BASE_URL}/v3/metasearch/flights/calendar/searches`,
    payload,
    {
      cancelToken,
    },
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const getFlightFareCalendar = async (payload: FareCalendarPayload) => {
  const response = await axios.post<FareCalendar>(
    `${API_BASE_URL}/v3/metasearch/flights/calendar/cheapest-prices`,
    payload,
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const getPaymentFees = async (paymentOrderId: string) => {
  const response = await axios.get(`${API_BASE_URL}/metasearch/bookings/flights/payments/fees`, {
    withCredentials: true,
    params: { orderId: paymentOrderId },
  });

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const getPaymentOrderData = async (paymentOrderId: string) => {
  const response = await axios.get(
    `${API_BASE_URL}/metasearch/bookings/flights/payments/order-data/${paymentOrderId}`,
    {
      withCredentials: true,
    },
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const getPaymentDetail = async (paymentId: string) => {
  const response = await axios.get(
    `${API_BASE_URL}/metasearch/bookings/flights/payments/${paymentId}`,
    {
      withCredentials: true,
    },
  );

  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};

export const getAncillaries = async (fareCode: string): Promise<SegmentAncillaries[]> => {
  const url = `${API_BASE_URL}/metasearch/bookings/flights/fare/${fareCode}/ancillaries`;

  const response = await axios.get(url);

  if (response.status === 200) {
    return response.data?.segmentAncillaries;
  }

  throw new Error(response.statusText);
};

export const getSeatAvailability = async (bookingRef: string): Promise<SeatAvailabilityProps> => {
  const url = `${API_BASE_URL}/metasearch/bookings/flights/ancillaries/seats-availability?bookingRef=${bookingRef}`;
  const response = await axios.get(url);
  if (response.status === 200) {
    return response.data;
  }
  throw new Error(response.statusText);
};
