import { datadogRum } from '@datadog/browser-rum';
import { useCallback } from 'react';

import { RecordMountActionParams, useDatadog } from '@context/Datadog';

export const DD = 'flight-search-result';
export const SABRE_FARE_DD = 'sabre-search-result';

const TIME_TO_FIRST_FETCH_ACTION_NAME = 'time between search submitted and first fetch';
const TIME_FROM_SEARCH_TO_FIRST_RESULT_ACTION_NAME =
  'time between search submitted and first result';
const TIME_FROM_FETCH_TO_RECEIVE_FIRST_DATA_ACTION_NAME =
  'time between fetch and first data received';
const TIME_TO_RENDER_FIRST_DATA_ACTION_NAME = 'time to render first data received';
const NUMBER_OF_FARES_IN_FIRST_POLL_ACTION_NAME = 'Number Of Fares In First Poll';
const TIME_FROM_SEARCH_TO_FIRST_SABRE_RESULT_ACTION_NAME =
  'time between search submitted and first sabre result';
const TIME_FROM_FETCH_TO_FIRST_SABRE_RESULT_ACTION_NAME =
  'time between fetch and first sabre result';

type OfferEngineCustomData = {
  flight_offer_engine_integration_ab_test_variant?: string;
};

const getRecordKey = (actionName: string) => `${DD}:${actionName}`;

const useFlightSearchResultsDatadog = () => {
  const {
    startRecordingAction,
    stopRecordingAction,
    recordMountAction,
    recordChangeAction,
    abortRecording,
  } = useDatadog();

  const abortSabreFareMountRecording = useCallback(() => {
    abortRecording(`${SABRE_FARE_DD}:${TIME_FROM_SEARCH_TO_FIRST_SABRE_RESULT_ACTION_NAME}`);
  }, [abortRecording]);

  const recordChangeFilterAction = useCallback(() => {
    recordChangeAction({ dd: DD, type: 'filter' });
    abortSabreFareMountRecording();
  }, [recordChangeAction, abortSabreFareMountRecording]);

  const recordChangeSortAction = useCallback(() => {
    recordChangeAction({ dd: DD, type: 'sort' });
    abortSabreFareMountRecording();
  }, [recordChangeAction]);

  const recordTimeToFirstResultAction = useCallback(() => {
    recordMountAction({
      dd: DD,
      actionName: TIME_FROM_SEARCH_TO_FIRST_RESULT_ACTION_NAME,
      customDataFromAttributeMap: {
        flight_offer_engine_integration_ab_test_variant:
          'data-dd-offer-engine-integration-ab-test-variant',
      },
    });
  }, [recordMountAction]);

  const startRecordingTimeToFirstFetchAction = useCallback(() => {
    startRecordingAction({ recordKey: getRecordKey(TIME_TO_FIRST_FETCH_ACTION_NAME) });
  }, [startRecordingAction]);

  const stopRecordingTimeToFirstFetchAction = useCallback(() => {
    stopRecordingAction({
      actionName: TIME_TO_FIRST_FETCH_ACTION_NAME,
      recordKey: getRecordKey(TIME_TO_FIRST_FETCH_ACTION_NAME),
    });
  }, [stopRecordingAction]);

  const startRecordingFetchTimeUntilFirstDataAction = useCallback(() => {
    startRecordingAction({
      recordKey: getRecordKey(TIME_FROM_FETCH_TO_RECEIVE_FIRST_DATA_ACTION_NAME),
    });
  }, [startRecordingAction]);

  const stopRecordingFetchTimeUntilFirstDataAction = useCallback(
    (customData: OfferEngineCustomData) => {
      stopRecordingAction({
        actionName: TIME_FROM_FETCH_TO_RECEIVE_FIRST_DATA_ACTION_NAME,
        recordKey: getRecordKey(TIME_FROM_FETCH_TO_RECEIVE_FIRST_DATA_ACTION_NAME),
        customData,
      });
    },
    [stopRecordingAction],
  );

  const recordRenderTimeFromFirstDataAction = useCallback(
    (params: Pick<RecordMountActionParams, 'onStop' | 'suppressAction'>) => {
      recordMountAction({ dd: DD, actionName: TIME_TO_RENDER_FIRST_DATA_ACTION_NAME, ...params });
    },
    [recordMountAction],
  );

  const recordNumberOfFaresInFirstPoll = useCallback((numberOfFares: number) => {
    datadogRum.addAction(NUMBER_OF_FARES_IN_FIRST_POLL_ACTION_NAME, {
      count: numberOfFares,
    });
  }, []);

  const recordTimeFromSearchToFirstSabreFareResult = useCallback(() => {
    recordMountAction({
      dd: SABRE_FARE_DD,
      actionName: TIME_FROM_SEARCH_TO_FIRST_SABRE_RESULT_ACTION_NAME,
      customDataFromAttributeMap: {
        flight_offer_engine_integration_ab_test_variant:
          'data-dd-offer-engine-integration-ab-test-variant',
      },
    });
  }, []);

  const startRecordingFetchTimeUntilFirstSabreFareResult = useCallback(() => {
    startRecordingAction({
      recordKey: getRecordKey(TIME_FROM_FETCH_TO_FIRST_SABRE_RESULT_ACTION_NAME),
    });
  }, [startRecordingAction]);

  const stopRecordingFetchTimeUntilFirstSabreFareResult = useCallback(
    (customData: OfferEngineCustomData) => {
      stopRecordingAction({
        actionName: TIME_FROM_FETCH_TO_FIRST_SABRE_RESULT_ACTION_NAME,
        recordKey: getRecordKey(TIME_FROM_FETCH_TO_FIRST_SABRE_RESULT_ACTION_NAME),
        customData,
      });
    },
    [stopRecordingAction],
  );

  const abortFlightSearchResultRecordings = () => {
    abortRecording(getRecordKey(TIME_TO_FIRST_FETCH_ACTION_NAME));
    abortRecording(getRecordKey(TIME_FROM_FETCH_TO_RECEIVE_FIRST_DATA_ACTION_NAME));
    abortRecording(getRecordKey(TIME_TO_RENDER_FIRST_DATA_ACTION_NAME));
    abortRecording(getRecordKey(TIME_FROM_SEARCH_TO_FIRST_RESULT_ACTION_NAME));
    abortRecording(getRecordKey(TIME_FROM_FETCH_TO_FIRST_SABRE_RESULT_ACTION_NAME));
    abortSabreFareMountRecording();
  };

  return {
    recordChangeFilterAction,
    recordChangeSortAction,
    recordTimeToFirstResultAction,
    recordTimeFromSearchToFirstSabreFareResult,

    startRecordingTimeToFirstFetchAction,
    startRecordingFetchTimeUntilFirstDataAction,

    stopRecordingTimeToFirstFetchAction,
    stopRecordingFetchTimeUntilFirstDataAction,
    recordRenderTimeFromFirstDataAction,

    recordNumberOfFaresInFirstPoll,

    abortFlightSearchResultRecordings,
    startRecordingFetchTimeUntilFirstSabreFareResult,
    stopRecordingFetchTimeUntilFirstSabreFareResult,
  };
};

export default useFlightSearchResultsDatadog;
